import React, { useContext } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { HubHeader, HubFooter, SEO, EmailHandler, About, Faq, Terms, Agendas } from 'components';
import { LocalContext } from 'context';
import { gridContainer, fullWidth, H5, H4, P, defaultColors, breakpoints } from 'styles';
import { useEventData } from 'hooks';

const Home = ({ data }) => {
  const { scrollTo, registerRef, theme } = useContext(LocalContext);
  const { edges: events } = useEventData();
  const { frontmatter: event } = data.markdownRemark;

  return (
    <>
      <SEO pageSpecificTitle="Home" />
      <EmailHandler />
      <HubHeader
        event={event}
        scrollToRegister={() => scrollTo(registerRef)}
        colors={defaultColors}
        events={events}
      />
      {/* <Agendas agendas={event.agendas} colors={event.colors} /> */}
      <About invert />
      <Section id="faq" theme={theme}>
        <Title>Frequently Asked Questions</Title>
        <FAQContainer theme={theme}>
          <Faq />
        </FAQContainer>
      </Section>
      <Terms />
      <HubFooter />
    </>
  );
};

export const query = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "skillnet-innovation-exchange-live" } }) {
      id
      frontmatter {
        name
        slug
        status
        eid
        colors {
          primary
          secondary
          tertiary
        }
        title
        subtitle
        banner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        speakers {
          id
          name
          position
          fbLink
          twLink
          linkedinLink
          emailLink
          shortSummary
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        agendas {
          day
          title
          slots {
            orderNumber
            title
            speaker
            speakerImages {
              imageOrder
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        description
        date
        start
        end
        location
        startTime
        endTime
      }
    }
  }
`;

const SpeakersContainer = styled.section`
  ${gridContainer}
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  width: 100%;
  ${H5} {
    margin: 0 auto;
    text-align: center;
    color: ${({ $theme }) => $theme.primary};
  }
  p {
    color: ${({ $theme }) => $theme.primary};
  }
  ${breakpoints.sm} {
    padding: 3rem 1.25rem;
  }
  ${breakpoints.lg} {
    padding: 6rem 0;
    grid-column: 1/8;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
    padding: 6rem 0;
  }
`;

const Section = styled.section`
  background-color: ${({ inverse, theme }) =>
    theme.className === 'contrast' ? theme.contrast : inverse ? '#EAF5F9' : 'transparent'};
  color: ${({ theme }) => theme.secondary};
  ${gridContainer}
  ${H4} {
    grid-column: 1/7;
  }
  ${P} {
    grid-column: 1/7;
  }
  button {
    grid-column: 1/7;
    margin: 0 auto;
  }
  padding: 3rem 1rem;
  @media only screen and (min-width: 768px) {
    padding: 6rem 1.25rem;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 6rem 0;
    ${H4} {
      grid-column: 2/12;
    }
    ${P} {
      grid-column: 2/12;
    }
    button {
      grid-column: 2/12;
    }
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
    padding: 6rem 0;
  }
  &.muted {
    background-color: ${({ theme }) =>
      theme.className === 'contrast' ? theme.contrast : '#f9f9f9'};
  }
`;

const Title = styled.h3`
  font-family: 'droid-sans', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 2rem;
  text-align: center;
  ${fullWidth}
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const FAQContainer = styled.div`
  align-items: start;
  color: ${({ theme }) => theme.secondary};
  grid-column: 1/7;
  justify-content: center;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

export default Home;
