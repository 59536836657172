import React, { useContext } from 'react';
import styled from 'styled-components';
import { RegistrationForm, Button, Modal, RenderWhenReady } from 'components';
import { navigate } from 'gatsby';
import { LocalContext, FirebaseContext } from 'context';
import { motion } from 'framer-motion';
import ICalendarLink from 'react-icalendar-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useWindowSize } from 'hooks';
import { InnovationExchangeLiveLogo, Add } from 'assets/svgs';
import { H2, H4, breakpoints } from 'styles';

const HubHeader = ({ event }) => {
  const eventDetails = {
    title: `${event.name}`,
    description: `Visit https://theinnovationexchangelive.ie/events/${event.slug} to start watching the event.`,
    location: 'Virtual Conference',
    startTime: `${event.start[0]}`,
    endTime: `${event.end[0]}`
  };
  const bannerImage = getImage(event.banner);
  const { theme, headerRef, isMobile } = useContext(LocalContext);
  const { loading, user, firebase } = useContext(FirebaseContext);
  const { windowWidth } = useWindowSize();
  const showComponent = !loading && firebase && !user;

  const colors = {
    primary: '#002a3a',
    secondary: '#FFFFFF',
    tertiary: '#64a70b'
  };

  return (
    <Header
      ref={headerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <Banner>
        <BannerImage image={bannerImage} alt="Hub Placeholder" />
      </Banner>
      <Container event={event.eid}>
        <Content>
          <InnovationExchangeLiveLogo style={{ position: 'relative', left: '-1.2rem' }} />
          <RenderWhenReady>
            <Text
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <Date
                $theme={theme}
                dangerouslySetInnerHTML={{
                  __html: event.date
                }}
              />
              {/* <ContrastTitle $theme={theme}><span>Welcome to the</span>{event.title}</ContrastTitle> */}
              <Title>
                <span style={{ color: theme.tertiary }}>Welcome to the </span>Skillnet Innovation
                Exchange LIVE
              </Title>
              {/* <Description
                style={{ opacity: 0, cursor: 'default' }}
                colors={colors}
                dangerouslySetInnerHTML={{ __html: event.description }}
              /> */}
              {isMobile && showComponent && (
                <RegisterButton style={{ opacity: 0, pointerEvents: 'none' }} $theme={theme}>
                  <Button width="200px" type="button">
                    <Modal trigger={<p>Register Here</p>} />
                  </Button>
                </RegisterButton>
              )}
              {isMobile && !loading && user && user?.eventsUserCanAccess?.includes(event.eid) && (
                <div
                  style={{
                    opacity: 0,
                    pointerEvents: 'none',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end'
                  }}>
                  <Button
                    shine
                    style={{
                      backgroundColor: theme.tertiary,
                      color: 'white',
                      fontFamily: '"droid-sans", sans-serif',
                      fontWeight: '700',
                      width: '250px',
                      height: '3.5rem',
                      marginBottom: '1rem',
                      lineHeight: '1.2rem',
                      marginTop: '1rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      pointerEvents: 'none'
                    }}>
                    Go To Livestream
                  </Button>
                </div>
              )}
            </Text>
          </RenderWhenReady>
          {event?.status === 'future' && (
            <StyledDateButton
              style={{ opacity: 0, pointerEvents: 'none' }}
              width="250px"
              theme={theme}>
              <AddIcon theme={theme} />
              <ICalendarLink
                style={{
                  height: '3rem',
                  background: 'transparent',
                  margin: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '-30px',
                  position: 'relative'
                }}
                event={eventDetails}>
                Add Date To Calendar
              </ICalendarLink>
            </StyledDateButton>
          )}
        </Content>
        {windowWidth >= 1150 && showComponent && (
          <FormContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            <RegistrationForm colors={colors} contrast event={event} />
          </FormContainer>
        )}
      </Container>
      <ArcContainer sendBack={showComponent && !isMobile}>
        <svg style={{ position: 'absolute' }}>
          <clipPath id="arc" clipPathUnits="objectBoundingBox">
            <path d="M1,0.264 V0.731 C0.665,0.619,0.328,0.724,0,1 V0.049 C0.334,-0.036,0.673,-0.038,1,0.264" />
          </clipPath>
          <clipPath id="mobile-arc" clipPathUnits="objectBoundingBox">
            <path d="M0,1 V0.087 C0.397,-0.01,0.832,-0.006,1,0.007 V0.763 C0.679,0.763,0.199,0.921,0,1" />
          </clipPath>
        </svg>
        <ArcInnerContainer>
          <Content>
            <RenderWhenReady>
              <Text>
                <Description
                  colors={colors}
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
                {isMobile && showComponent && (
                  <RegisterButton
                    $theme={theme}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1 }}>
                    <Button width="200px" type="button">
                      <Modal
                        trigger={<p>Register Here</p>}
                        modalContent={<RegistrationForm colors={colors} contrast />}
                      />
                    </Button>
                  </RegisterButton>
                )}
                {isMobile && !loading && user && user?.eventsUserCanAccess?.includes(event.eid) && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-end'
                    }}>
                    <Button
                      shine
                      onClick={() => navigate(`/events/${event.slug}/livestream`)}
                      whileHover={{ scale: 1.025 }}
                      whileTap={{ scale: 0.95 }}
                      style={{
                        backgroundColor: theme.tertiary,
                        color: 'white',
                        fontFamily: '"droid-sans", sans-serif',
                        fontWeight: '700',
                        width: '250px',
                        height: '3.5rem',
                        marginBottom: '1rem',
                        lineHeight: '1.2rem',
                        marginTop: '1rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      Go To Livestream
                    </Button>
                  </motion.div>
                )}
                {event?.status === 'future' && (
                  <StyledDateButton
                    whileHover={{ scale: 1.025 }}
                    whileTap={{ scale: 0.95 }}
                    width="250px"
                    theme={theme}>
                    <AddIcon theme={theme} />
                    <ICalendarLink
                      style={{
                        height: '3rem',
                        background: 'transparent',
                        margin: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '-30px',
                        position: 'relative'
                      }}
                      event={eventDetails}>
                      Add Date To Calendar
                    </ICalendarLink>
                  </StyledDateButton>
                )}
              </Text>
            </RenderWhenReady>
          </Content>
          {windowWidth >= 1150 &&
            !loading &&
            user &&
            user?.eventsUserCanAccess?.includes(event.eid) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Button
                  shine
                  onClick={() => navigate(`/events/${event.slug}/livestream`)}
                  whileHover={{ scale: 1.025 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    backgroundColor: theme.tertiary,
                    color: 'white',
                    borderRadius: '10px',
                    fontSize: '1.75rem',
                    fontFamily: '"droid-sans", sans-serif',
                    fontWeight: '700',
                    width: '320px',
                    height: '4.5rem',
                    marginBottom: '1.75rem',
                    lineHeight: '2rem',
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  Go To Livestream
                </Button>
              </motion.div>
            )}
        </ArcInnerContainer>
      </ArcContainer>
    </Header>
  );
};

const Content = styled.div`
  align-self: end;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 3;
  ${breakpoints.sm} {
    padding: 3em 1em 1em 1rem;
  }
  ${breakpoints.lg} {
    padding: 2em 1em 1em 1rem;
  }
`;

const Text = styled(motion.div)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  width: 100%;
`;

const Title = styled(H2)`
  color: #ffffff;
  font-weight: 700;
  margin: 0;
  ${breakpoints.lg} {
    font-size: 2.875rem;
    line-height: 1;
  }
`;

const ContrastTitle = styled(Title)`
  color: ${({ $theme }) => $theme.tertiary};
  font-weight: 700;
  line-height: 0;
  margin-top: 0;
`;

const Description = styled(motion.p)`
  color: #ffffff;
  font-family: 'droid-sans', sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  left: -0.5rem;
  line-height: 1.469em;
  position: relative;
  z-index: 5;

  ul {
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
`;

const FormContainer = styled(motion.div)`
  align-self: end;
  height: 100%;
  padding: 0.625rem;
  position: relative;
  z-index: 2;
`;

const Container = styled.div`
  align-items: center;
  grid-column: 1 / 7;
  justify-content: center;
  padding: 6rem 1rem 3rem 1rem;
  width: 100%;
  z-index: 4;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 6rem 0 1rem 0;
  }
  ${breakpoints.lg} {
    display: grid;
    gap: 3rem;
    grid-column: 2 / 12;
    grid-template-columns: 55% 45%;
  }
`;

const Date = styled(H4)`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: uppercase;
  span {
    color: ${({ $theme }) => $theme.tertiary};
  }
  @media (min-width: 1150px) {
    font-weight: 700;
  }
`;

const StyledDateButton = styled(motion.div)`
  align-items: center;
  background: transparent;
  border-radius: 30px;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  left: 1rem;
  padding: 1rem 1rem 1rem 0;
  position: relative;
  width: 280px;
  z-index: 2;
  a {
    align-items: center;
    background: white;
    background-color: transparent;
    color: ${({ theme }) => theme.tertiary};
    cursor: pointer;
    display: flex;
    font-family: 'Uni Neue', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    justify-content: flex-start;
    list-style: none;
    margin: 0.5rem 0;
    text-transform: uppercase;
    transition: 0.5s;
    width: ${(props) => (props.width ? props.width : '100%')};
  }
  svg {
    circle {
      fill: ${({ theme }) => theme.tertiary};
    }
    path {
      stroke: #fff;
    }
  }
  @media (min-width: 1024px) {
    left: -0.5rem;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

const AddIcon = styled(Add)`
  height: 3rem;
  margin-right: 0.5rem;
  width: 1.5rem;
`;

const BannerImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
`;

const Banner = styled.div`
  grid-column: 1/7;
  height: 100%;
  left: 0;
  max-height: 900px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  @media (min-width: 1024px) {
    grid-column: 1/13;
  }
`;

const Header = styled.header`
  background: white;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  position: relative;
  width: 100%;
  z-index: 3;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const ArcContainer = styled.div`
  background-color: #052a39;
  background-size: cover;
  bottom: 0;
  clip-path: url(#mobile-arc);
  display: grid;
  grid-column: 1 / 7;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  left: 0;
  margin-bottom: -22rem;
  padding-bottom: 7.75rem;
  position: absolute;
  width: 100%;
  z-index: ${({ sendBack }) => (sendBack ? '1' : '5')};
  ${breakpoints.sm} {
    margin-bottom: -22rem;
  }
  ${breakpoints.lg} {
    clip-path: url(#arc);
    grid-column: 1 / 13;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: -6.625rem;
  }
`;

const ArcInnerContainer = styled.div`
  display: grid;
  grid-column: 1 / 7;
  padding: 4rem 1rem 1rem 1rem;
  ${breakpoints.sm} {
    padding: 2rem 0 0 0;
  }
  ${breakpoints.lg} {
    grid-column: 2 / 12;
    grid-template-columns: 55% 45%;
  }
`;

const RegisterButton = styled(motion.div)`
  margin-left: 0.5rem;
  margin-top: 1rem;
  button {
    align-items: center;
    background-color: ${({ $theme }) => $theme.fg};
    display: flex;
    justify-content: center;
    padding: 1.75rem 1rem;
  }
`;

export default HubHeader;
