import styled from 'styled-components';

export const breakpoints = {
  xs: '@media (min-width: 480px)',
  sm: '@media (min-width: 520px)',
  md: '@media (min-width: 992px)',
  lg: '@media (min-width: 1150px)',
  xl: '@media (min-width: 1441px)'
};

export const defaultColors = {
  primary: '#002a3a',
  secondary: '#01788C',
  tertiary: '#64a70b'
};

export const Grid = styled.div`
  align-items: start;
  display: grid;
  grid-column: 1/7;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  ${breakpoints.sm} {
    grid-column: 1/12;
    grid-template-columns: repeat(10, 1fr);
  }
  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-column-gap: 0rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const H1 = styled.h1`
  color: inherit;
  font-family: 'Uni Neue', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-left: -3px;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 3rem;
    margin-bottom: 1em;
  }
`;

export const H2 = styled.h2`
  color: inherit;
  font-family: 'Uni Neue', sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 2.75rem;
    margin-bottom: 1em;
  }
`;

export const H3 = styled.h3`
  color: inherit;
  font-family: 'Uni Neue', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 2.25rem;
    margin-bottom: 1em;
  }
`;

export const H4 = styled.h4`
  color: inherit;
  font-family: 'Uni Neue', sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 2rem;
    margin-bottom: 1em;
  }
`;

export const H5 = styled.h5`
  color: inherit;
  font-family: 'Uni Neue', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }
`;

export const H6 = styled.h6`
  color: inherit;
  font-family: 'Uni Neue', sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 1.25rem;
    margin-bottom: 1em;
  }
`;

export const P = styled.p`
  color: inherit;
  font-family: 'Uni Neue', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    margin-bottom: 1em;
  }
`;

export const CardDeck = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-row-gap: 1.5rem;
  grid-template-columns: 1fr;
  position: relative;
  width: 90vw;
  ${breakpoints.sm} {
    grid-column-gap: ${({ large }) => (large ? '3rem' : '2rem')};
    grid-row-gap: 3rem;
    grid-template-columns: repeat(8, 1fr);
    justify-content: center;
    width: 95vw;
    padding: 0 1rem;
    div {
      grid-column: span 4;
    }
  }
  ${breakpoints.lg} {
    grid-column-gap: ${({ large }) => (large ? '3rem' : '2rem')};
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    padding: 0 3rem;
    width: 100vw;
    div {
      grid-column: span 2;
      &:last-child:nth-child(3n - 1) {
        grid-column-end: -2;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      &:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      }
    }
  }
`;

export const container = () =>
  ` padding: 0px 1.25rem;
    grid-column: 1/7;
   ${breakpoints.sm}{
      grid-column: 2/6;
    }
    ${breakpoints.lg}{
        grid-column: 2/12;
    }
    ${breakpoints.xl}{
      grid-column: 3/11;
    }
    `;

export const gridContainer = () =>
  `
    padding: 0px 1.25rem;
    grid-column: 1/7;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
   ${breakpoints.sm}{
      grid-column: 2/6;
    }
    ${breakpoints.lg}{
        grid-column: 2/12;
        grid-template-columns: repeat(10, 1fr);
        grid-gap: 1.25rem;
    }
    ${breakpoints.xl}{
      grid-column: 3/11;
    }
    `;

export const fullGridContainer = () =>
  `
    padding:0px 1.25rem;
    grid-column: 1/7;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap:.625rem;
    ${breakpoints.lg}{
        grid-column: 1/13;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 1.25rem;
    }
    `;

export const flexContainer = () =>
  `
    grid-column: 1/7;
    display: flex;
   ${breakpoints.sm}{
      grid-column: 2/6;
    }
    ${breakpoints.lg}{
        grid-column: 2/12;
    }
    ${breakpoints.xl}{
      grid-column: 3/11;
    }
    `;

export const noPadding = () => `
    padding:0px;`;

export const fullWidth = () => `
grid-column: 1/7;
@media (min-width: 768px){
  grid-column: 2/6;
}
${breakpoints.lg}{
  grid-column: 2/12;
}
${breakpoints.xl}{
  grid-column: 3/11;
}
`;

export const fadeInAndOutVariants = ({ animateDelay = 0, exitDelay = 0 } = {}) => ({
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: animateDelay
    }
  },
  exit: {
    opacity: 0,
    transition: {
      delay: exitDelay
    }
  }
});

export const fadeInAndOutAndAnimateHeightVariants = () => ({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      opacity: {
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      height: {
        delay: 0.1
      }
    }
  }
});

export default {
  container,
  gridContainer,
  fullGridContainer,
  flexContainer,
  noPadding,
  fullWidth,
  breakpoints
};
