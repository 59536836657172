import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { fullGridContainer, breakpoints, H6 } from 'styles';
import {
  InnovationExchangeLiveLogoWhite,
  SkillnetTransparentLogo,
  EuropeanFlag
} from 'assets/svgs';
import Harp from '../../../assets/images/department-harp.png';

const HubFooter = () => {
  const { theme, contactRef } = useContext(LocalContext);
  return (
    <>
      <GridContainer $theme={theme}>
        <Subscribe $theme={theme}>
          <a href="https://www.theinnovationexchange.ie" target="blank">
            <InnovationExchangeLiveLogoWhite width={280} />
          </a>
          <P>
            The Skillnet Innovation Exchange LIVE broadcast
            <br /> is delivered by Get Broadcasting
          </P>
        </Subscribe>
        <Contact id="contact" ref={contactRef}>
          <H6 style={{ marginBottom: '0.625rem' }}>Support</H6>
          <ContactDetail $theme={theme} href="mailto:support@theinnovationexchangelive.ie">
            support@theinnovationexchangelive.ie
          </ContactDetail>
          <H6 style={{ marginBottom: '0.625rem' }}>Contact</H6>
          <ContactDetail $theme={theme} href="mailto:info@theinnovationexchange.iee">
            info@theinnovationexchange.ie
          </ContactDetail>
        </Contact>
      </GridContainer>
      <FooterSponsorInfo $theme={theme}>
        <div>
          <span>
            Skillnet Innovation Exchange is an enterprise initiative of Furhr. Skillnet Innovation Exchange is
            co-funded by Skillnet Ireland and participating businesses. Skillnet Ireland is funded
            from the National Training Fund through the Department of Further and Higher Education,
            Research, Innovation and Science.
          </span>
        </div>
        <div>
          <SkillnetTransparentLogo />
        </div>
        <div>
          <img src={Harp} alt="harp" />
          <Department>
            <strong>
              An Roinn Breisoideachais agus Ardoideachais, Taighde, Nuálaíochta agus Eolaíochta
            </strong>
            <br />
            Department of Further and Higher Education, Research, Innovation and Science.
          </Department>
        </div>
        <div>
          <EuropeanFlag />
          <p>
            Co-funded by
            <br /> the European Union
          </p>
        </div>
      </FooterSponsorInfo>
      <FooterInfo $theme={theme}>
        <FooterCopyright $theme={theme}>
          Copyright © 2024 - Designed and Developed by Agency X
        </FooterCopyright>
        <FooterTerms $theme={theme}>
          <a href="https://theinnovationexchange.ie/privacy-policy-2/" target="blank">
            Terms & Conditions
          </a>
          <a href="https://theinnovationexchange.ie/privacy-policy-2/" target="blank">
            Privacy Policy
          </a>
        </FooterTerms>
      </FooterInfo>
    </>
  );
};

const GridContainer = styled.footer`
  align-items: center;
  background: ${({ $theme }) => $theme.primary};
  ${fullGridContainer}
  color: ${({ $theme }) => $theme.tertiary};
  grid-row-gap: 0.75rem;
  margin-bottom: 0.25rem;
  margin-top: -1px;
  padding: 2.25rem 1.25rem;
  position: relative;
  ${breakpoints.sm} {
    padding: 40px;
  }
  ${breakpoints.lg} {
    grid-gap: auto;
    padding: 2.25rem 0;
  }
`;

const P = styled.p`
  font-family: 'Uni Neue', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  margin-top: 0px;
  ${breakpoints.lg} {
    margin-bottom: 0;
  }
`;

const Subscribe = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  svg {
    margin-bottom: 1.875rem;
    max-width: 280px;
  }
  ${P} {
    font-weight: 700;
  }
  ${breakpoints.sm} {
    grid-column: 1/5;
  }
  ${breakpoints.lg} {
    grid-column: 2/6;
  }
`;

const Contact = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  ${breakpoints.sm} {
    grid-column: 5/7;
    justify-self: flex-end;
  }
  ${breakpoints.lg} {
    grid-column: 9/12;
  }
`;

const ContactDetail = styled.a`
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: 'Uni Neue', sans-serif;
  font-weight: 500;
  justify-content: flex-start;
  text-decoration: none;
  transition: 0.5s;
  :visited {
    color: #ffffff;
  }
  &:first-of-type {
    margin-bottom: 2.25rem;
  }
  svg {
    height: 1.75rem;
    margin-right: 0.75rem;
    min-width: 1.75rem;
    width: 1.75rem;
    circle {
      fill: ${({ $theme }) => $theme.tertiary};
      transition: 0.5s;
    }
    path {
      stroke: 'white';
      transition: 0.5s;
    }
  }
  &:hover {
    color: ${({ $theme }) => $theme.tertiary};
    svg {
      circle {
        fill: 'white';
      }
      path {
        stroke: ${({ $theme }) => $theme.tertiary};
      }
    }
  }
`;

const FooterSponsorInfo = styled.div`
  ${fullGridContainer}
  align-items: center;
  background: ${({ $theme }) => $theme.primary};
  color: #ffffff;
  font-family: 'Uni Neue', sans-serif;
  font-weight: 700;
  padding: 1rem 1.25rem;
  div {
    align-items: center;
    display: flex;
    gap: 1rem;
    span {
      font-size: 0.5rem;
      line-height: 0.75rem;
    }
    p {
      font-family: 'Arial', sans-serif;
      font-size: 0.5rem;
      line-height: 0.75rem;
    }
    svg {
      width: 50%;
      height: auto;
    }
    &:nth-of-type(1) {
      grid-column: 1 / 5;
    }
    :nth-of-type(2) {
      align-self: end;
      grid-column: 5 / 7;
    }
    :nth-of-type(3) {
      display: grid;
      grid-column: 1 / 5;
      grid-template-columns: 60px 1fr;
      width: 100%;
      img {
        width: 100%;
        height: auto;
        padding-left: 1rem;
      }
    }
    :nth-of-type(4) {
      grid-column: 5 / 7;
      svg {
        max-width: 90px;
      }
    }
  }
  ${breakpoints.sm} {
    div {
      :nth-of-type(2) {
        svg {
          width: 50%;
        }
      }
      span {
        font-size: 0.625rem;
        line-height: 0.875rem;
      }
      p {
        font-size: 1.3125rem;
        line-height: 1.5rem;
      }
    }
  }
  ${breakpoints.lg} {
    grid-column: 2 / 13;
    padding: 12px 0px;
    div {
      &:nth-of-type(1) {
        grid-column: 2 / 5;
      }
      &:nth-of-type(2) {
        grid-column: 5 / 6;
        align-self: center;
        svg {
          width: 100%;
        }
      }
      :nth-of-type(3) {
        grid-column: 6 / 9;
        display: grid;
        width: 100%;
      }
      :nth-of-type(4) {
        grid-column: 9 / 12;
        svg {
          max-width: 90px;
        }
      }
    }
  }
`;

const Department = styled.span`
  font-family: 'Times New Roman', serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
`;

const FooterInfo = styled.footer`
  ${fullGridContainer}
  align-items: start;
  background: rgba(189, 189, 189, 0.3);
  font-family: 'Uni Neue', sans-serif;
  font-weight: 300;
  padding: 1rem 1.25rem;
  ${breakpoints.sm} {
    grid-column: 1/7;
  }
  ${breakpoints.lg} {
    grid-column: 2/13;
    padding: 12px 0px;
  }
`;

const FooterCopyright = styled.p`
  color: ${({ $theme }) => $theme.primary};
  font-size: 0.75rem;
  font-weight: 300;
  grid-column: 1/7;
  line-height: 1.25rem;
  margin-top: 0px;
  ${breakpoints.sm} {
    grid-column: 1/4;
  }
  ${breakpoints.lg} {
    grid-column: 2/7;
    left: 1rem;
  }
`;

const FooterTerms = styled.div`
  display: flex;
  grid-column: 1/7;
  justify-content: flex-start;
  a {
    color: ${({ $theme }) => $theme.primary};
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-right: 1.25rem;
  }
  ${breakpoints.sm} {
    grid-column: 4/7;
    justify-content: flex-end;
  }
  ${breakpoints.lg} {
    grid-column: 9/12;
  }
`;

export default HubFooter;
