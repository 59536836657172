import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { InnovationExchangeLogo, User, Hub, Help } from 'assets/svgs';
import { Modal, RegistrationForm, LoginModal, UserProfile, NavbarDropdown } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { fullGridContainer, breakpoints } from 'styles';
import { motion } from 'framer-motion';
import { isDesktop } from 'react-device-detect';

const Navbar = ({ colors }) => {
  const { theme, isMobile } = useContext(LocalContext);
  const { pathname } = useLocation();
  const { navOpen, setNavOpen } = useContext(LocalContext);
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(pathname);
  let isMounted = true;

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => setActiveLink(pathname), [pathname]);

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  const handleLogout = () => {
    firebase.userProfile
      .logout()
      .then(() => {
        document.querySelector('html').classList.remove('scroll-lock');
        navigate('/');
      })
      .catch((error) => isMounted && console.error(error.message));
  };

  return (
    <StyledNavbar
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      scrolled={isScrolled}
      $theme={theme}>
      <LogoContainer
        to="/"
        aria-label="Home"
        onClick={() => {
          toggleActiveLink('welcome');
        }}>
        <InnovationExchangeLogo />
      </LogoContainer>
      {/* {(navOpen || !isMobile) && (
        <NavLinks
          initial={isMobile ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.75 }}>
          {isMobile && !loading && firebase?.auth?.currentUser && user && (
            <LoginButton $theme={theme}>
              <Modal
                trigger={
                  <>
                    <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                      {user.name?.split(' ')[0] || user.email}
                    </p>
                    {user.avatarUrl ? (
                      <ProfileImage src={user.avatarUrl} alt={user.fullName} $theme={theme} />
                    ) : (
                      <User />
                    )}
                  </>
                }
                modalContent={<UserProfile handleLogout={handleLogout} />}
                colors={colors}
              />
            </LoginButton>
          )}
          {isMobile && !loading && !firebase?.auth?.currentUser && !user && (
            <>
              <RegisterButton $theme={theme}>
                <Modal
                  trigger={<p>Register</p>}
                  modalContent={<RegistrationForm colors={colors} />}
                  colors={colors}
                />
              </RegisterButton>

              <LoginButton $theme={theme}>
                <Modal
                  hideCloseButton
                  trigger={
                    <>
                      <p>Login </p>
                      <User />
                    </>
                  }
                  modalContent={<LoginModal colors={colors} />}
                  colors={colors}
                />
              </LoginButton>
            </>
          )}
        </NavLinks>
      )} */}
      {!isMobile && !loading && firebase?.auth?.currentUser && user && (
        <UserIcon $theme={theme}>
          <Modal
            hideCloseButton={isDesktop}
            trigger={
              <>
                <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                  {user.name?.split(' ')[0] || user?.email}
                </p>
                {user.avatarUrl ? (
                  <ProfileImage src={user.avatarUrl} alt={user?.name} $theme={theme} />
                ) : (
                  <User />
                )}
              </>
            }
            modalContent={<UserProfile handleLogout={handleLogout} />}
          />
        </UserIcon>
      )}
      {!isMobile && !loading && !firebase?.auth?.currentUser && !user && (
        <UserIcon $theme={theme}>
          <Modal
            transparentCloseButtonBackground
            hideCloseButton
            trigger={
              <>
                {' '}
                <p style={{ marginRight: '.625rem', fontWeight: '700' }}>Login</p>
                <User />
              </>
            }
            modalContent={<LoginModal colors={colors} />}
          />
        </UserIcon>
      )}
      <NavToggler
        onClick={() => {
          toggleNav();
        }}
        $theme={theme}
        aria-label="Menu">
        <NavBurger $theme={theme} navOpen={navOpen}>
          <BurgerBar $theme={theme} navOpen={navOpen} />
          <BurgerBar $theme={theme} navOpen={navOpen} />
          <BurgerBar $theme={theme} navOpen={navOpen} />
        </NavBurger>
      </NavToggler>
    </StyledNavbar>
  );
};

const ProfileImage = styled.img`
  background: #c4c4c4;
  border: 0.125rem solid ${({ $theme }) => $theme.tertiary};
  border-radius: 50%;
  height: 1.625rem;
  object-fit: cover;
  width: 1.625rem;
`;

const LogoContainer = styled(Link)`
  grid-area: logo;
  svg {
    height: 4.563rem;
    margin-left: 1.25rem;
    margin-top: 0.25rem;
    object-fit: cover;
    width: auto;
    z-index: 2;
  }
  ${breakpoints.lg} {
    grid-column: 2/3;
    svg {
      margin-left: 0;
    }
  }
`;

const RegisterButton = styled.div`
  background: ${({ $theme }) => $theme.tertiary};
  border: none;
  color: 'white';
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 3rem;
  outline: none;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

const LoginButton = styled.button`
  background: #f3f3f3;
  border: none;
  cursor: pointer;
  font-family: 'Uni Neue', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 3rem;
  max-width: 100%;
  outline: none;
  padding-top: 0.125rem;
  text-align: center;
  width: 100%;
  div {
    align-items: center;
    color: ${({ $theme }) => $theme.primary};
    display: flex;
    justify-content: center;
  }

  svg {
    max-height: 1.25rem;
    margin-left: 0.5em;
    path {
      fill: ${({ $theme }) => $theme.primary};
    }

    circle {
      stroke: ${({ $theme }) => $theme.primary};
    }
  }

  ${breakpoints.lg} {
    background: transparent;
    justify-content: flex-end;
  }
`;

const UserIcon = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  grid-area: user;
  grid-column: 10/12;
  height: 100%;
  outline: 0;
  width: 100%;
  div {
    align-items: center;
    color: ${({ $theme }) => $theme.primary} !important;
    display: flex;
    font-family: 'Uni Neue', sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    justify-content: center;
    svg {
      max-height: 1.25rem;
    }
  }

  ${breakpoints.lg} {
    div {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      width: 100%;
    }
  }

  svg {
    circle {
      stroke: ${({ $theme }) => $theme.primary};
    }
    path {
      fill: ${({ $theme }) => $theme.primary};
    }
  }
`;

const NavToggler = styled.button`
  align-items: center;
  background: ${({ $theme }) => $theme.primary};
  cursor: pointer;
  display: flex;
  grid-area: toggle;
  height: 3.75rem;
  justify-content: center;
  justify-self: end;
  width: 3.75rem;
  ${breakpoints.lg} {
    display: none;
  }
`;

const NavBurger = styled.div`
  height: 1rem;
  position: relative;
  width: ${({ navOpen }) => (navOpen === true ? '1rem' : '1.25rem')};
`;

const BurgerBar = styled.span`
  background: #ffffff;
  content: '';
  display: block;
  height: 0.185rem;
  left: 0;
  position: absolute;
  transform-origin: center center;
  transition: 0.375s;
  width: 1.25rem;
  &:first-child {
    top: ${({ navOpen }) => (navOpen === true ? '50%' : '0')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(45deg)' : 'none')};
  }
  &:nth-child(2) {
    opacity: ${({ navOpen }) => (navOpen === true ? '0' : '1')};
    top: 50%;
    transform: translateY(-50%);
  }
  &:last-child {
    bottom: ${({ navOpen }) => (navOpen === true ? 'initial' : '0')};
    top: ${({ navOpen }) => (navOpen === true ? '50%' : 'initial')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(-45deg)' : 'none')};
  }
`;

const NavLink = styled(Link)`
  align-items: center;
  background: white;
  color: ${({ $theme }) => $theme.secondary};
  cursor: pointer;
  display: flex;
  font-family: 'Uni Neue', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 3rem;
  padding-top: 0.125rem;
  text-align: center;
  width: 100%;
  svg {
    fill: ${({ $theme }) => $theme.secondary};
    margin-right: 0.5rem;
    width: 0.75rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    background: transparent;
    font-size: 0.875rem;
    line-height: 1.5rem;
    width: auto;
    margin-bottom: 0.125rem;
  }
`;

const NavLinks = styled(motion.div)`
  align-items: center;
  backdrop-filter: blur(2px);
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  grid-area: links;
  grid-gap: 0px;
  height: ${({ windowHeight }) => `calc(${windowHeight}px - 60px)`};
  justify-content: center;
  ${breakpoints.lg} {
    align-items: center;
    background: transparent;
    box-shadow: none;
    flex-direction: row;
    grid-column: 3/6;
    height: 4.875rem;
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0px;
  }
`;

const StyledNavbar = styled(motion.nav)`
  ${fullGridContainer}
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  grid-gap: 0px;
  grid-template-areas:
    'logo toggle'
    'links links';
  grid-template-columns: 1fr 60px;
  height: 3.75rem;
  left: 0;
  max-width: 100vw;
  padding: 0px;
  position: fixed;
  top: 0;
  transition-duration: 0.75s;
  width: 100%;
  z-index: 5;
  ${breakpoints.lg} {
    box-shadow: ${({ scrolled }) =>
      scrolled ? '0px .185rem .75rem rgba(0,0,0, 0.25)' : 'transparent'};
    grid-gap: 1.25rem;
    grid-template-areas: 'logo links user help';
    grid-template-columns: repeat(12, 1fr);
    height: 4.875rem;
  }
`;

const HelpHub = styled(Link)`
  align-items: center;
  background: ${({ open }) => (open ? '#F3F3F3' : 'transparent')};
  cursor: pointer;
  display: flex;
  grid-area: help;
  height: 3.75rem;
  justify-content: flex-end;
  justify-self: end;
  position: relative;
  transition: 0.375s;
  width: 3.75rem;
  z-index: 1;
  svg {
    height: 1.25rem;
    margin: 0.5rem;
    max-height: 1.25rem;
    path {
      stroke: ${({ theme }) => theme.secondary};
      fill: ${({ theme }) => theme.secondary};
    }
    circle {
      stroke: ${({ theme }) => theme.secondary};
    }
  }
  ${breakpoints.lg} {
    grid-column: 10/11;
    height: 4rem;
    justify-self: center;
    width: 100%;
  }
`;

export default Navbar;
