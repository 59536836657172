import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FirebaseContext } from 'context';
import {
  CommentArrow,
  DefaultCommenterAvatar,
  EmojiClap,
  EmojiLike,
  EmojiLove,
  EmojiSmile
} from 'assets/svgs';
import { fadeInAndOutVariants } from 'styles';
import { scrollToAnchor, hexToRGB } from 'utils';
import { isMobile } from 'react-device-detect';
import Interweave from 'interweave';
import { UrlMatcher, EmailMatcher } from 'interweave-autolink';
import { format } from 'date-fns';
import { Pin, ThumbsUp } from 'assets/animated-svgs';
import { MentionsMatcher } from './matchers';

const Chat = ({
  colors,
  eid,
  eventStatus,
  handleParticipantAvatarOrNameClick,
  chatTextInputValue,
  setChatTextInputValue,
  chatPreviousTextInputValue,
  setChatPreviousTextInputValue,
  editingComment,
  setEditingComment,
  idOfCommentCurrentlyBeingEdited,
  setIdOfCommentCurrentlyBeingEdited,
  textOfCommentBeforeBeingEdited,
  setTextOfCommentBeforeBeingEdited
}) => {
  const { firebase, user } = useContext(FirebaseContext);
  const [allComments, setAllComments] = useState([]);
  const [showEmojisSelector, setShowEmojisSelector] = useState(false);

  useEffect(() => {
    let unsubscribeFromEventComments;

    if (firebase) {
      unsubscribeFromEventComments = firebase.interaction.chat.subscribeToEventComments({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            const comments = [];

            snapshot.forEach((doc) => {
              comments.push({
                cid: doc.id,
                ...doc.data()
              });
            });

            setAllComments(comments);
          } else if (snapshot.empty) {
            setAllComments([]);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventComments) {
        unsubscribeFromEventComments();
      }
    };
  }, [firebase]);

  const resetEditingCommentState = () => {
    setEditingComment(false);
    setIdOfCommentCurrentlyBeingEdited(null);
    setChatTextInputValue('');
    setTextOfCommentBeforeBeingEdited('');
    setChatPreviousTextInputValue('');
  };

  const handleTextInput = async (e) => {
    e.persist();
    setChatTextInputValue(e.target.value);
  };

  const handleSubmitEventComment = (e) => {
    e.preventDefault();
    if (e.target.value !== '' && e.target.value !== ' ') {
      if (!editingComment) {
        firebase.interaction.chat
          .submitEventComment({
            avatarUrl: user.avatarUrl,
            eid,
            name: user.name,
            profession: user.profession,
            text: chatTextInputValue,
            uid: user.uid,
            company: user.company,
            socials: user.socials
          })
          .then(() => {
            setChatTextInputValue('');
            setChatPreviousTextInputValue('');
            scrollToAnchor('livestream', -90, 800);
          })
          .catch(console.error);
      } else {
        firebase.interaction.chat
          .editEventComment({
            eid,
            cid: idOfCommentCurrentlyBeingEdited,
            updatedText: chatTextInputValue
          })
          .then(() => {
            resetEditingCommentState();
            scrollToAnchor('livestream', -90, 800);
          })
          .catch(console.error);
      }
    }
  };

  const handleEditComment = ({ cid, text, tagsArray }) => {
    if (editingComment && cid === idOfCommentCurrentlyBeingEdited) {
      resetEditingCommentState();
    } else {
      setEditingComment(true);
      setIdOfCommentCurrentlyBeingEdited(cid);
      setChatTextInputValue(text);
      setTextOfCommentBeforeBeingEdited(text);
      setChatPreviousTextInputValue(text);
    }
  };

  const handleDeleteComment = ({ cid }) => {
    if (editingComment && cid === idOfCommentCurrentlyBeingEdited) {
      resetEditingCommentState();
    }
    firebase.interaction.chat.deleteEventComment({ eid, cid });
  };

  const handlePinComment = ({ cid, isPinned }) => {
    if (editingComment && cid !== idOfCommentCurrentlyBeingEdited) {
      resetEditingCommentState();
    }
    if (isPinned) {
      firebase.interaction.chat.unpinEventComment({ eid, cid });
    } else {
      firebase.interaction.chat.pinEventComment({ eid, cid });
    }
  };

  const handleEmojiClick = (emojiType) =>
    firebase.interaction.emojis.sendEmoji({ eid, emojiType }).catch(console.error);

  return (
    <Wrapper>
      <EventComments colors={colors} tabIndex={0}>
        {allComments.length > 0 ? (
          <AnimatePresence initial={false}>
            {allComments.map((comment) => (
              <Comment
                layout
                key={comment.cid}
                colors={colors}
                isPinned={comment.pinned.status}
                initial={{
                  scale: 0,
                  opacity: 0
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: 0.9,
                    type: 'spring'
                  }
                }}
                exit={{
                  scale: 0,
                  opacity: 0,
                  transition: {
                    duration: 0.6,
                    type: 'tween'
                  }
                }}>
                {comment.avatarUrl ? (
                  <CommenterAvatar
                    onClick={() => handleParticipantAvatarOrNameClick(comment)}
                    size="1.625rem"
                    src={comment.avatarUrl}
                    alt={comment.name}
                    colors={colors}
                  />
                ) : (
                  <DefaultCommenterAvatar
                    onClick={() => handleParticipantAvatarOrNameClick(comment)}
                  />
                )}
                <CommentTextAndButtons colors={colors}>
                  <p>
                    <span
                      onClick={() => handleParticipantAvatarOrNameClick(comment)}
                      role="button"
                      tabIndex={0}>
                      {comment.name}
                    </span>
                    <Interweave
                      content={comment.text}
                      // matchers={[new UrlMatcher('url')]}
                      matchers={[
                        new UrlMatcher('url'),
                        new MentionsMatcher('mention'),
                        new EmailMatcher('email')
                      ]}
                      colors={colors}
                      tagName="q"
                      newWindow
                    />
                  </p>
                  {comment.timestamp && (
                    <small>{format(comment.timestamp.toDate(), 'HH:mm')}</small>
                  )}
                  {(user?.isModerator?.includes(eid) || user?.uid === comment?.uid) &&
                    eventStatus !== 'past' && (
                      <CommentOptions
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleDeleteComment({ cid: comment.cid })}>
                        Delete
                      </CommentOptions>
                    )}
                  {user?.uid === comment?.uid && eventStatus !== 'past' && (
                    <CommentOptions
                      whileTap={{ scale: 0.9 }}
                      onClick={() => handleEditComment({ cid: comment.cid, text: comment.text })}
                      style={{
                        opacity:
                          editingComment && comment.cid === idOfCommentCurrentlyBeingEdited
                            ? 0.4
                            : 1
                      }}>
                      Edit
                    </CommentOptions>
                  )}
                  {user?.isModerator?.includes(eid) && eventStatus !== 'past' && (
                    <PinComment
                      colors={colors}
                      isPinned={comment.pinned.status}
                      whileTap={{ scale: 0.9 }}
                      onClick={() =>
                        handlePinComment({
                          cid: comment.cid,
                          isPinned: comment.pinned.status
                        })
                      }>
                      <Pin
                        stroke={comment.pinned.status ? colors.tertiary : '#000'}
                        fill={comment.pinned.status ? colors.tertiary : 'transparent'}
                      />
                      {comment.pinned.status ? 'Unpin' : 'Pin'}
                    </PinComment>
                  )}
                </CommentTextAndButtons>
              </Comment>
            ))}
          </AnimatePresence>
        ) : (
          <p style={{ color: '#000', paddingTop: '1.25rem' }}>Add a comment below...</p>
        )}
      </EventComments>
      <CommentForm onSubmit={handleSubmitEventComment} autoComplete="off">
        <AddComment>
          <CommentInput
            disabled={eventStatus === 'past'}
            onChange={handleTextInput}
            value={chatTextInputValue}
            id="comment"
            name="comment"
            type="text"
            placeholder={
              eventStatus === 'past'
                ? 'Chat only available during live broadcast'
                : user
                ? '...'
                : 'Chat only available to registered users'
            }
            colors={colors}
          />
          <ChatButtons>
            <SubmitComment
              whileTap={{ scale: 0.9 }}
              disabled={
                chatTextInputValue === '' ||
                chatTextInputValue === ' ' ||
                (editingComment && chatTextInputValue === textOfCommentBeforeBeingEdited) ||
                eventStatus === 'past'
              }
              colors={colors}
              brighten={chatTextInputValue}
              type="submit">
              <CommentArrow width="1rem" />
            </SubmitComment>
            <Emojis
              onTap={() =>
                isMobile &&
                eventStatus !== 'past' &&
                setShowEmojisSelector((currentValue) => !currentValue)
              }
              disabled={eventStatus === 'past'}
              onHoverStart={() => eventStatus !== 'past' && setShowEmojisSelector(true)}
              onHoverEnd={() => setShowEmojisSelector(false)}
              colors={colors}>
              <AnimatePresence>
                {showEmojisSelector && (
                  <EmojiSelector
                    variants={fadeInAndOutVariants({
                      exitDelay: 0.1
                    })}
                    initial="initial"
                    animate="animate"
                    exit="exit">
                    <div>
                      <Emoji onClick={() => user && handleEmojiClick('smile')}>
                        <EmojiSmile />
                      </Emoji>
                      <Emoji onClick={() => user && handleEmojiClick('love')}>
                        <EmojiLove />
                      </Emoji>
                      <Emoji onClick={() => user && handleEmojiClick('like')}>
                        <EmojiLike />
                      </Emoji>
                      <Emoji onClick={() => user && handleEmojiClick('clap')}>
                        <EmojiClap />
                      </Emoji>
                      <div />
                    </div>
                  </EmojiSelector>
                )}
              </AnimatePresence>
              <ThumbsUp colors={colors} width="1.25rem" />
            </Emojis>
          </ChatButtons>
        </AddComment>
      </CommentForm>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
`;

export const CommenterAvatar = styled.img`
  border: 0.125rem solid ${({ colors }) => colors.tertiary};
  border-radius: 50%;
  height: ${({ size }) => size};
  object-fit: cover;
  position: relative;
  width: ${({ size }) => size};
`;

const AddComment = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CommentForm = styled.form`
  background: white;
  border-top: 1px solid #c4c4c4;
  bottom: 0;
  height: auto;
  left: 0;
  padding: 1rem;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const EventComments = styled.div`
  border-top: none;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: calc(100% - 4.5rem);
  min-height: 18.75rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1rem;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const Comment = styled(motion.div)`
  align-items: flex-start;
  background-color: ${({ isPinned, colors }) =>
    isPinned ? hexToRGB({ color: colors.tertiary, alpha: 0.2 }) : 'transparent'};
  color: #000;
  display: flex;
  margin-bottom: 0.7em;
  padding: 0.125em 0.25em 0;

  &:first-of-type {
    margin-top: 1.25rem;
  }

  /* Avatars */
  > img:first-child,
  > svg:first-child {
    flex-shrink: 0;
    margin-right: 0.5rem;
    position: relative;
    top: 0.313rem;
    width: 1.625rem;
    cursor: pointer;
  }

  > div {
    bottom: 0.375rem;
    position: relative;

    p {
      color: ${({ isPinned, colors }) => (isPinned ? colors.tertiary : '#000')};
      font-size: 1rem;
      line-height: 1.25em;
      margin-top: 0.575em;
      q {
        &:before {
          content: '';
        }
        &:after {
          content: '';
        }
      }

      span {
        cursor: pointer;
        color: ${({ isPinned, colors }) => (isPinned ? colors.tertiary : '#c4c4c4')};
        font-weight: ${({ isPinned }) => (isPinned ? '700' : '500')};
        margin-right: 0.475rem;
      }
    }

    small {
      bottom: 0.125em;
      display: inline-block;
      font-size: 0.65rem;
      left: 0.063em;
      position: relative;

      &:last-of-type {
        color: ${({ isPinned, colors }) => (isPinned ? colors.tertiary : '#000')};
      }
    }
  }
`;

const CommentTextAndButtons = styled.div`
  p {
    color: ${({ colors }) => colors.secondary};
  }
  a {
    color: ${({ colors }) => colors.tertiary};
    text-decoration: underline;
  }
`;

const CommentOptions = styled(motion.button)`
  background-color: transparent;
  bottom: 0.125em;
  cursor: pointer;
  font-size: 0.65rem;
  margin-left: 0.5em;
  position: relative;
  text-decoration: underline;
`;

export const PinComment = styled(CommentOptions)`
  color: ${({ isPinned, colors }) => (isPinned ? colors.tertiary : '#000')};
  margin-left: 2.15em;
  position: relative;

  > svg {
    bottom: -0.1em;
    height: 1.2em;
    left: -1.45em;
    position: absolute;
    width: 1.2em;
  }
`;

const ChatButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 6.25rem;

  > * {
    margin-left: 0.625rem;
  }
`;

const CommentInput = styled.input`
  align-items: center;
  background-color: transparent;
  border: 1px solid #c4c4c4;
  border-bottom: 1px solid;
  border-color: #c4c4c4;
  border-radius: 2px;
  color: #000;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  height: 2.5rem;
  justify-content: flex-start;
  letter-spacing: 0.5px;
  line-height: 2rem;
  outline-color: #c4c4c4;
  padding: 0.625rem;
  width: calc(100% - 6.25rem);

  &:focus {
    outline-color: ${({ colors }) => colors.tertiary};
  }

  ::placeholder {
    color: #bdbdbd;
    font-size: ${({ disabled }) => (disabled ? '0.875rem' : '1rem')};
    font-style: ${({ disabled }) => (disabled ? 'normal' : 'italic')};
    font-weight: 500;
  }
`;

const SubmitComment = styled(motion.button)`
  align-items: center;
  background-color: ${({ brighten, colors }) =>
    brighten ? colors.tertiary : 'rgba(196, 196, 196, 0.3)'};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 2.5rem;
  justify-content: center;
  transition: background-color 0.25s ease-in-out, stroke 0.25s ease-in-out,
    outline-color 0.25s ease-in-out;
  width: 2.5rem;

  svg path {
    stroke: ${({ brighten }) => brighten && '#fff'};
  }

  &:focus {
    outline-color: ${({ colors }) => colors.tertiary};
  }
`;

const EmojiSelector = styled(motion.div)`
  height: 3.25rem;
  position: absolute;
  right: -0.063rem;
  top: -3.125rem;
  width: 10rem;

  > div {
    background-color: #fff;
    box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.14);
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
    padding: 0.5rem 0.688rem;
    position: relative;

    /* Speech bubble triangle */
    > div {
      background-color: #fff;
      bottom: -0.25rem;
      height: 0.5rem;
      pointer-events: none;
      position: absolute;
      right: 0.875rem;
      transform: rotate(45deg);
      width: 0.5rem;
    }
  }
`;

const Emojis = styled(motion.div)`
  align-items: center;
  background-color: #fff;
  border: 1px solid ${({ colors, disabled }) => (disabled ? '#C4C4C4' : colors.tertiary)};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 2.5rem;
  justify-content: center;
  position: relative;
  transition: background-color 0.25s ease-in-out, fill 0.25s ease-in-out;
  width: 2.5rem;
  > svg path {
    fill: ${({ colors, disabled }) => (disabled ? '#C4C4C4' : colors.tertiary)};
  }

  &:hover {
    background-color: ${({ colors, disabled }) => (disabled ? 'transparent' : colors.tertiary)};

    > svg path {
      fill: ${({ disabled }) => (disabled ? '#C4C4C4' : '#FFFFFF')};
    }
  }
`;

const Emoji = styled(motion.span).attrs({
  whileTap: {
    scale: 0.9
  },
  whileHover: {
    scale: 1.1
  }
})`
  display: inline-flex;

  svg {
    width: 1.5rem;
  }
`;

export default Chat;
