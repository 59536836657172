import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { breakpoints, gridContainer, H3, P } from 'styles';
import { LocalContext } from 'context';
import { Markup } from 'interweave';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { Arrow } from 'assets/svgs';

const variants = {
  enter: (direction) => ({
    opacity: 0,
    x: direction > 0 ? 100 : -100
  }),
  center: {
    opacity: 1,
    x: 0,
    zIndex: 1
  },
  exit: (direction) => ({
    opacity: 0,
    x: direction < 0 ? 100 : -100,
    zIndex: 0
  })
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

const SpeakerPopup = ({ startPoint, speakers, colors }) => {
  const { isMobile, theme } = useContext(LocalContext);
  const [[page, direction], setPage] = useState([startPoint, 0]);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const speakerIndex = wrap(0, speakers.length, page);

  const speakerImage = getImage(speakers[speakerIndex].image);

  return (
    <Backdrop>
      <AnimatePresence exitBeforeEnter custom={direction}>
        {!isMobile && (
          <>
            <Prev
              $theme={theme}
              onClick={() => paginate(-1)}
              whileTap={{ scale: 0.9 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              $colors={colors}>
              <Arrow />
            </Prev>
            <Container
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: 0.5 }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}>
              <Slide $theme={theme}>
                <SpeakerImgContainer>
                  <SpeakerImg image={speakerImage} />
                </SpeakerImgContainer>
                <SpeakerDetails>
                  <SpeakerName theme={theme}>
                    <Markup content={speakers[speakerIndex].name} />
                  </SpeakerName>
                  <SpeakerTitle theme={theme} $colors={colors}>
                    <Markup content={speakers[speakerIndex].shortSummary} noHtml />
                  </SpeakerTitle>
                  <SpeakerSummary theme={theme}>
                    <Markup content={speakers[speakerIndex].summary} />
                  </SpeakerSummary>
                </SpeakerDetails>
              </Slide>
            </Container>
            <Next
              $theme={theme}
              onClick={() => paginate(1)}
              whileTap={{ scale: 0.9 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              $colors={colors}>
              <Arrow />
            </Next>
          </>
        )}

        {isMobile && (
          <Container>
            <Slide $theme={theme}>
              <SpeakerImgContainer>
                <SpeakerImg image={getImage(speakers[speakerIndex].image)} />
              </SpeakerImgContainer>
              <SpeakerDetails>
                <SpeakerName theme={theme}>{speakers[speakerIndex].name}</SpeakerName>
                <SpeakerTitle theme={theme}>{speakers[speakerIndex].shortSummary}</SpeakerTitle>
                <SpeakerSummary theme={theme}>{speakers[speakerIndex].summary}</SpeakerSummary>
              </SpeakerDetails>
            </Slide>
          </Container>
        )}
      </AnimatePresence>
    </Backdrop>
  );
};

const Paginate = styled(motion.button)`
  align-items: center;
  background-color: ${({ $colors }) => $colors.tertiary};
  border: none;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  justify-self: end;
  left: initial;
  opacity: 0.8;
  outline: none;
  position: absolute;
  right: initial;
  top: calc(50% - 1.5rem);
  width: 3rem;
  z-index: 3;
  svg {
    transform: rotate(90deg);
    transform-origin: center;
    width: 1.5rem;
    path {
      stroke: #f3f3f3;
    }
  }
`;

const Prev = styled(Paginate)`
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  left: 0;
  opacity: 0.8;
  padding-left: 0.625rem;
  svg {
    margin-right: 0.5rem;
    transform: rotate(90deg);
  }
`;

const Next = styled(Paginate)`
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  padding-right: 0.625rem;
  right: 0;
  svg {
    margin-left: 0.5rem;
    transform: rotate(-90deg);
  }
`;

const SpeakerImgContainer = styled.div`
  background: transparent;
  grid-column: 1/7;
  justify-self: center;
  margin-bottom: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-top: -50%;
  max-width: 100%;
  position: relative;
  width: 300px;

  ${breakpoints.lg} {
    align-self: center;
    grid-column: 1/5;
    height: auto;
    margin-left: -50%;
    margin-right: 50%;
    margin-top: 25px;
    max-width: 100%;
    width: 300px;
  }
`;

const SpeakerImg = styled(GatsbyImage)`
  border-radius: 50%;
  box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: auto;
  min-height: 275px;
  object-fit: cover !important;
  object-position: center !important;
  position: relative;
  width: 100%;
  z-index: 2;
  ${breakpoints.lg} {
    min-height: 300px;
  }
`;

const SpeakerName = styled(H3)`
  color: ${({ theme }) => theme.secondary};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
`;

const SpeakerTitle = styled(P)`
  color: ${({ $colors }) => $colors.tertiary};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  margin-top: 0px;
  max-width: 420px;
  text-align: left;
  br {
    display: block !important;
  }
`;

const SpeakerSummary = styled(P)`
  color: ${({ theme }) => theme.primary};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
`;

const SpeakerDetails = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  margin-left: 0px;

  ${breakpoints.lg} {
    grid-column: 5/11;
    margin-left: -150px;
    padding: 2.5rem;
  }
`;

const Slide = styled.div`
  align-items: center;
  ${gridContainer}
  background: #F3F3F3;
  color: ${({ $theme }) => $theme.primary};
  height: auto;
  margin-bottom: 3rem;
  margin-top: 10rem;
  max-width: 100%;
  min-height: 400px;
  padding: 1.25rem;
  position: relative;
  width: 1200px;
  ${breakpoints.lg} {
    margin-left: 200px;
    margin-right: 5rem;
    margin-top: 0px;
  }
`;

const Container = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding-top: 1rem;
  width: 1200px;
  ${breakpoints.sm} {
    max-width: 400px;
  }
  ${breakpoints.lg} {
    max-width: 100%;
    padding-top: 0;
  }
`;

const Backdrop = styled.div`
  height: auto;
  width: auto;
  @media (max-width: 1150px) {
    overflow-x: hidden;
  }
`;

export default SpeakerPopup;
